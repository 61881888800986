/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { toPng } from "html-to-image";

import CryptoJS from "crypto-js";

import dayjs from "dayjs";

import { saveAs } from "file-saver";

import GENERATE_ACCESS_EVENT_INFORMATION from "@graphql/mutations/getAccessEventInformation";
import REGISTER_TO_EVENT from "@graphql/mutations/registerToEvent";
import {
  GENERATE_ATTENDANCE_REPORT,
  GENERATE_REGISTRATION_REPORT,
} from "@graphql/open-events/mutation";
import GET_EVENT_METRICS from "@graphql/queries/eventMetrics";
import GET_HOSTING_AND_UPCOMING_EVENTS from "@graphql/queries/upcomingEvents";
import GET_USER from "@graphql/queries/users";

import Button from "@components/Button/Button";
import Loading from "@components/Loading/Loading";
import Menu from "@components/Menu/Menu";
import { TextTruncated } from "@components/utils/TextTruncated/TextTruncated";

import { LiveHeader } from "./LiveHeader/LiveHeader";

import TooltipComponent from "@components/Tooltip/Tooltip";

import Checkmark from "@images/checkEvent.svg";

import {
  displayNotSuccessNotification,
  displayServerError,
  displaySuccessNotification,
} from "@services/NotificationService/NotifacitonService";

import { formatDate, isPastEvent } from "@utils/eventsHelper";
import { useDownloadFile } from "@utils/files/useDownloadFile";
import { useTimeout } from "@utils/useTimeout";

import { createEvent } from "ics";

import { IEvent } from "types/event";
import EventConfirmJoinModal from "../EventConfirmJoinModal/EventConfirmJoinModal";
import { IOption } from "../Menu/types";
import EventMetricsCard from "./EventMetricsCard/EventMetricsCard";

import { ShareCTA } from "./ShareCTA/ShareCTA";

import { ReactComponent as DownloadIcon } from "@images/downloadIcon.svg";
import { ReactComponent as EditSingle } from "@images/editSingle.svg";
import { ReactComponent as InfoLogo } from "@images/moreInfo.svg";
import { ReactComponent as TrashIcon } from "@images/trashCanBlue.svg";

import { UNSYNCED_TIME_ZONES } from "@modules/Events/types/unsyncedTimeZones";
import styles from "./EventTimeCard.module.scss";

interface IEventTimeCard {
  startDate: string;
  endDate: string;
  name: string;
  eventDescription?: string;
  hostingId?: number;
  publicId?: string;
  startTime: Date;
  endTime: Date;
  isLoading: boolean;
  externalUrl?: string;
  eventType?: string;
  host?: string;
  hostEmail?: string;
  setOpenDeleteEventModal: (state: boolean) => void;
}

const getDelayForMakingEventJoinable = (
  startDate: string,
  minutesBeforeEventIsJoinable: number
) => {
  const timeBeforeEventIsJoinable = minutesBeforeEventIsJoinable * 60 * 1000;
  const timeEventStarts = new Date(startDate).getTime();
  const timeEventBecomesJoinable = timeEventStarts - timeBeforeEventIsJoinable;
  return timeEventBecomesJoinable - new Date().getTime();
};

const MINUTES_BEFORE_EVENT_IS_JOINABLE = 30;
const cryptKey = `Vk5uVWXWs4L`;
const attendeesContainerName = "attendees";

const EventTimeCard = ({
  startDate,
  endDate,
  name,
  eventDescription,
  hostingId,
  publicId,
  isLoading,
  startTime,
  endTime,
  externalUrl,
  eventType,
  host,
  hostEmail,
  setOpenDeleteEventModal,
}: IEventTimeCard) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [attend, setAttend] = useState<boolean>(false);
  const [checkmark, setCheckmark] = useState<boolean>(false);
  const [registerToEvent] = useMutation(REGISTER_TO_EVENT);
  const [generateRegistrationReport] = useMutation(GENERATE_REGISTRATION_REPORT);
  const [generateAttendanceReport] = useMutation(GENERATE_ATTENDANCE_REPORT);
  const [description, setDescription] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [liveMode, setLiveMode] = useState<boolean>(false);
  const [generateAccessEventInformation] = useMutation(GENERATE_ACCESS_EVENT_INFORMATION);
  const [attendeesTotalCount, setAttendeesTotalCount] = useState<number>();
  const [attendeesCountriesTotalCount, setAttendeesCountriesTotalCount] =
    useState<number>();
  const [confirmJoinModal, setConfirmJoinModal] = useState<boolean>(false);
  const { data } = useQuery(GET_USER);
  const [isEventJoinable, setIsEventJoinable] = useState<boolean>(false);
  const [delay, setDelay] = useState(0);
  const attendanceFileName = `${name}_Attendance.csv`;
  const registrationsFileName = `${name}_Registrations.csv`;

  const [userTimeZone, setUserLocalTimezone] = useState<string>("");

  const params = useParams<{ id: string }>();
  const downloadFile = useDownloadFile();

  const elementRef = useRef<HTMLDivElement>(null);

  const htmlToImageConvert = () => {
    displaySuccessNotification(t("events.fileIsBeingGenerated"));

    if (elementRef.current === null) return;

    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${name}_MetricsCard.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        displayNotSuccessNotification();
      });
  };

  const getDownloadOptions = () => {
    let options: IOption[];

    options = [
      {
        label: t("downloadEventRegistrationsList"),
        action: () => downloadEventRegistrations(),
      },
    ];

    if (eventHasPassed && !externalUrl) {
      options = [
        ...options,

        {
          label: t("downloadEventAttendanceList"),
          action: () => downloadEventAttendance(),
        },
        {
          label: t("downloadEventMetricsCard"),
          action: () => getEventMetrics({ variables: { publicId: params.id } }),
          // htmlToImageConvert();
        },
      ];
    }

    return options;
  };

  useTimeout(() => {
    setIsEventJoinable(true);
  }, delay);

  useEffect(() => {
    setIsEventJoinable(false);
    setDelay(getDelayForMakingEventJoinable(startDate, MINUTES_BEFORE_EVENT_IS_JOINABLE));
  }, [startDate]);

  const joinEvent = () => {
    if (externalUrl) {
      setConfirmJoinModal(true);
      return;
    }
    startWebinar();
  };

  const startWebinar = () => {
    generateAccessEventInformation({
      variables: {
        hostingId,
      },
    })
      .then(({ data: { getAccessEventInformation } }) => {
        if (!getAccessEventInformation || !publicId || !hostingId) return;
        const cryptedValue = {
          sdkKey: getAccessEventInformation.sdkKey,
          signature: getAccessEventInformation.signature,
          meetingNumber: hostingId,
          userName: data?.user.fullName || "",
          userEmail: getAccessEventInformation.joinEmail,
          userPublicId: data?.user.publicId || "",
        };

        const encJson = CryptoJS.AES.encrypt(
          JSON.stringify(cryptedValue),
          cryptKey
        ).toString();
        const encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
        window.open(`${window.location.origin}/meeting?p=${publicId}&m=${encData}`);
      })
      .catch((error) => displayNotSuccessNotification(error));
  };

  useEffect(() => {
    const date1 = dayjs(startTime);
    const date2 = dayjs(endTime);

    const difference = date1.diff(dayjs(), "minutes");
    const endDifference = date2.diff(dayjs(), "minutes");
    if (difference <= 30 && endDifference > 0) {
      setDisableButton(false);
    } else setDisableButton(true);
    if (difference <= 0 && endDifference > 0) setLiveMode(true);
    else setLiveMode(false);
  }, [startTime, endTime]);
  const [getEventMetrics] = useLazyQuery(GET_EVENT_METRICS, {
    onCompleted: (data) => {
      setAttendeesTotalCount(data.event.attendeesTotalCount);
      setAttendeesCountriesTotalCount(data.event.attendeesCountriesTotalCount);
      htmlToImageConvert();
    },
  });

  const [getHostingAndUpcomingEvents, { loading }] = useLazyQuery(
    GET_HOSTING_AND_UPCOMING_EVENTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (
          data.user.hostingAndAttendingUpcomingEvents.some(
            (e: IEvent) => e.publicId === publicId
          )
        ) {
          setAttend(true);
          setCheckmark(true);
          setDescription(true);
        }
      },
      onError: (error) => displayServerError(error),
    }
  );

  const eventHasPassed = isPastEvent(endDate);

  useEffect(() => {
    getHostingAndUpcomingEvents();
    let userDefaultLocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // This is added because of unsync between the IANA standard time zones and time zones fetched from browsers
    if (userDefaultLocalTimeZone === UNSYNCED_TIME_ZONES.ASIA_CALCUTTA)
      userDefaultLocalTimeZone = UNSYNCED_TIME_ZONES.ASIA_KOLKATA;
    if (userDefaultLocalTimeZone === UNSYNCED_TIME_ZONES.ASIA_ULANBATOR)
      userDefaultLocalTimeZone = UNSYNCED_TIME_ZONES.ASIA_ULAANBATAAR;

    setUserLocalTimezone(userDefaultLocalTimeZone);
  }, []);

  const attendToEvent = () => {
    registerToEvent({
      variables: {
        eventRegistrationInput: {
          eventPublicId: publicId,
          timeZone: userTimeZone,
        },
      },
    })
      .then(() => {
        setAttend(true);
        setCheckmark(true);
        setDescription(true);
      })
      .catch(() => displayServerError());
  };

  const getEventTypeFromResponse = (param: string) => {
    switch (param) {
      case "Public":
        return "public";
      case "Private":
        return "private";
      case "OpenToPublic":
        return "openToPublic";
      default:
        return "public";
    }
  };

  const showEventType = () => () => t("events." + getEventTypeFromResponse(eventType!));

  const downloadEventForCalendar = () => {
    const startDateHelper = new Date(startDate);
    const startTimeForIcs = [
      startDateHelper.getFullYear(),
      startDateHelper.getMonth() + 1,
      startDateHelper.getDate(),
      startDateHelper.getHours(),
      startDateHelper.getMinutes(),
    ];
    const endDateHelper = new Date(endDate);
    const endTimeForIcs = [
      endDateHelper.getFullYear(),
      endDateHelper.getMonth() + 1,
      endDateHelper.getDate(),
      endDateHelper.getHours(),
      endDateHelper.getMinutes(),
    ];

    const desc = `
    ${eventDescription ? eventDescription : ""}
    ${t("events.eventUrl")}:  ${window.location.href}
    `;

    const event = {
      start: startTimeForIcs,
      end: endTimeForIcs,
      title: name,
      description: desc,
      location: t("termsAndConditions.title"),
      url: window.location.href,
      organizer: { name: host, email: hostEmail },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    createEvent(event as any, (error, value) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      saveAs(blob, `${name}.ics`);
    });
  };

  const downloadEventRegistrations = () => {
    displaySuccessNotification(t("events.fileIsBeingGenerated"));
    generateRegistrationReport({
      variables: {
        eventPublicId: publicId,
      },
    })
      .then(() => {
        downloadRegistrations(publicId!);
      })
      .catch(() => displayServerError());
  };

  const downloadRegistrations = (publicId: string) => {
    downloadFile(registrationsFileName, publicId, true, attendeesContainerName);
  };

  const downloadEventAttendance = () => {
    displaySuccessNotification(t("events.fileIsBeingGenerated"));
    generateAttendanceReport({
      variables: {
        eventPublicId: publicId,
      },
    })
      .then(() => {
        downloadAttendance(publicId!);
      })
      .catch(() => displayServerError());
  };

  const downloadAttendance = (publicId: string) => {
    downloadFile(attendanceFileName, publicId, true, attendeesContainerName);
  };

  return loading ? (
    <div className={styles.loadingContainer}>
      <Loading disableBoxShadow />
    </div>
  ) : (
    <div className={styles.container} aria-label="container">
      {!liveMode ? (
        <div>
          <div className={styles.heading}>
            <div className={styles.titleWrapper}>
              {checkmark && <img className={styles.img} src={Checkmark} />}
              <h4 aria-label="event-name" className={styles.title}>
                <TextTruncated text={name} lines={3} />
              </h4>
            </div>
            <div className={styles.buttons}>
              <TooltipComponent
                title={t("events.exportEvent")}
                tooltipType="secondary"
                placement="bottomRight"
                childToWrap={
                  <>
                    <Button
                      title=""
                      buttonIcon={<DownloadIcon />}
                      buttonShape="circle"
                      buttonSize="middle"
                      wrapperClassNames={styles.buttonCTA}
                      onClick={() => downloadEventForCalendar()}
                    />
                  </>
                }
              />
              {host === data?.user.fullName && !eventHasPassed && (
                <Button
                  title=""
                  buttonIcon={<TrashIcon />}
                  buttonShape="circle"
                  buttonSize="middle"
                  wrapperClassNames={styles.buttonCTA}
                  onClick={() => setOpenDeleteEventModal(true)}
                />
              )}
              {host === data?.user.fullName && (
                <Button
                  title=""
                  buttonIcon={<EditSingle />}
                  buttonShape="circle"
                  buttonSize="middle"
                  wrapperClassNames={styles.buttonCTA}
                  onClick={() => history.push(`/portal/event/edit/${publicId}`)}
                />
              )}
              {host === data?.user.fullName && (
                <Menu
                  options={getDownloadOptions()}
                  placement="left"
                  children={
                    <Button
                      title=""
                      buttonIcon={<InfoLogo />}
                      buttonShape="circle"
                      buttonSize="middle"
                      wrapperClassNames={styles.buttonCTA}
                    />
                  }
                />
              )}
              <ShareCTA />
            </div>
          </div>

          <div className={styles.descriptionContainer}>
            <span className={styles.eventType}>{showEventType()}</span>
            {description && !isPastEvent(endDate) && (
              <span aria-label="description" className={styles.description}>
                {t("eventDetail.attendingDescription")}
              </span>
            )}
          </div>
        </div>
      ) : (
        <LiveHeader name={name} />
      )}
      <div>
        <div className={styles.bottomContainer}>
          <div className={styles.rowSecond}>
            <div className={styles.column}>
              <span className={styles.timeTitle}>{t("eventDetail.date.start")}</span>
              <span aria-label="date-start" className={styles.time}>
                {formatDate(new Date(startDate), i18n.language)}
              </span>
            </div>
            <span className={styles.border} />
            <div className={styles.columnSecond}>
              <span className={styles.timeTitle}>{t("eventDetail.date.end")}</span>
              <span aria-label="date-end" className={styles.time}>
                {formatDate(new Date(endDate), i18n.language)}
              </span>
            </div>
          </div>
        </div>

        {!attend ? (
          <Button
            aria-label="attend-event"
            onClick={attendToEvent}
            title={
              eventHasPassed
                ? t("eventDetail.buttons.eventPassed")
                : t("eventDetail.buttons.attendEvent")
            }
            disabled={eventHasPassed}
            className={styles.button}
          />
        ) : (
          <Button
            aria-label="join-btn"
            title={
              eventHasPassed
                ? t("eventDetail.buttons.eventPassed")
                : t("eventDetail.buttons.joinEvent")
            }
            onClick={joinEvent}
            disabled={disableButton || !isEventJoinable || isLoading}
            className={styles.button}
          />
        )}
        {externalUrl && (
          <EventConfirmJoinModal
            text={t("events.joinExternalLinkText")}
            isOpen={confirmJoinModal}
            joinUrl={externalUrl}
            closeModal={() => setConfirmJoinModal(false)}
          />
        )}
      </div>
      <div className={styles.htmlCardWrapper}>
        <div ref={elementRef}>
          <EventMetricsCard
            name={name}
            eventType={eventType!}
            date={formatDate(new Date(startDate), i18n.language)}
            attendeesTotalCount={attendeesTotalCount!}
            attendeesCountriesTotalCount={attendeesCountriesTotalCount!}
          />
        </div>
      </div>
    </div>
  );
};

export default EventTimeCard;
