import { mapToOption } from "@utils/selectHelper";
import { ITimeZone } from "types/timeZone";
import { UNSYNCED_TIME_ZONES } from "../types/unsyncedTimeZones";

export const getDefaultTimeZoneOption = (timeZones: ITimeZone[]) => {
  if (!timeZones.length) return;

  let userLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // This is added because of unsync between the IANA standard time zones and time zones fetched from browsers
  if (userLocalTimezone === UNSYNCED_TIME_ZONES.ASIA_CALCUTTA)
    userLocalTimezone = UNSYNCED_TIME_ZONES.ASIA_KOLKATA;
  if (userLocalTimezone === UNSYNCED_TIME_ZONES.ASIA_ULANBATOR)
    userLocalTimezone = UNSYNCED_TIME_ZONES.ASIA_ULAANBATAAR;

  const userLocalTimezoneValue = timeZones.find((zone) =>
    zone.timeZones.map((timeZone) => timeZone.name).includes(userLocalTimezone)
  );

  return mapToOption(
    userLocalTimezoneValue!.timeZones[0].name,
    userLocalTimezoneValue!.name
  );
};
